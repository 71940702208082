import React from 'react'
import { Link } from 'gatsby'
import MarkdownView from 'react-showdown'
import { FaArrowRight } from 'react-icons/fa'
import { formatDate } from '../util/common'

const PostCard = ({ post, withLinks }) => {
  const { tags = [] } = post
  return (
    <article key={post.slug} className="flex flex-col shadow my-4">
      {/* <a href="#" className="hover:opacity-75">
  <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=2" />
</a> */}
      <div className="bg-white flex flex-col justify-start p-6">
        {tags.map(tag => (
          <Link
            className="text-blue-700 text-sm font-bold uppercase pb-4"
            to={`/tag/${tag.slug}`}
          >
            #{tag.title}
          </Link>
        ))}

        {withLinks ? (
          <Link
            to={`/${post.slug}`}
            className="text-3xl font-bold hover:text-gray-700 pb-4"
          >
            {post.title}
          </Link>
        ) : (
          <span className="text-3xl font-bold hover:text-gray-700 pb-4">
            {post.title}
          </span>
        )}
        <p className="text-sm pb-3">
          By{' '}
          <span className="font-semibold hover:text-gray-800">
            {post.author.name}
          </span>
          , Published on {formatDate(post.created_on)}
        </p>
        {withLinks ? (
          <>
            <Link to={`/${post.slug}`} className="pb-6">
              {post.excerpt}
            </Link>
            <Link
              to={`/${post.slug}`}
              className="uppercase text-gray-800 hover:text-black flex items-center"
            >
              <span className="mr-2">Continue Reading</span>
              <FaArrowRight />
            </Link>
          </>
        ) : (
          <div>
            <MarkdownView markdown={post.body} options={{ emoji: true }} />
          </div>
        )}
      </div>
    </article>
  )
}

PostCard.defaultProps = {
  withLinks: true,
}
export default PostCard
